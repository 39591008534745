import React from "react";
import dotenv from "dotenv";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import AppRoutes from "./AppRoutes";
import * as serviceWorker from "./serviceWorker";

import "react-app-polyfill/ie11";
import "regenerator-runtime";
import "url-search-params-polyfill";

dotenv.config();

Sentry.init({
  dsn: "https://e52e9e8f1d7a47af9122a18019fa28c4@o913885.ingest.sentry.io/5879097",
  integrations: [new Integrations.BrowserTracing()],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  environment: process.env.REACT_APP_ENV || "development",
});

ReactDOM.render(<AppRoutes />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
