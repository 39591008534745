import { addMinutes, isSameDay, getHours, getMinutes } from "date-fns";
import { format } from "date-fns-tz";

/**
 * 배송 상태 표시
 */
export const STATUS = [
  "접수",
  "수거지정",
  "수거완료",
  "수거완료", // 원래는 입고
  "출고",
  "배송완료",
  "반송완료",
  "예약완료",
];

/**
 * 배송 예상 시간 표시
 */
export const getEstimatedTime = (order) => {
  const current = new Date();

  const from = addMinutes(new Date(), order * 10);
  const to = addMinutes(new Date(), (order * 10) + 120);

  let displayFrom = format(from, "H시m분") 
  if (!isSameDay(current, from) || (getHours(from) >= 22 && getMinutes(from) >= 30)) {
    displayFrom = "22시30분"
  }

  let displayTo = format(to, "H시m분") 
  if (!isSameDay(current, to) || (getHours(to) >= 22 && getMinutes(to) >= 30)) {
    displayTo = "22시30분"
  }

  return `${displayFrom} ~ ${displayTo}`
};
