import React, { useCallback, useEffect, useState } from "react";
import styled, { css } from "styled-components";

import { withQueryParams, StringParam } from "use-query-params";
import { differenceInMinutes } from "date-fns";
import { format } from "date-fns-tz";
import queryString from "query-string";

import Banner from "../components/Banner";
import Footer from "../components/Footer";
import Nav from "../components/Nav";

import { useDeliveryStore, useNavStore } from "../stores/hooks";

const BodyContent = styled.div`
  overflow: hidden;
  position: relative;
  min-height: 100vh;
  background-color: #202020;
  color: #fff;

  ${({ navOpen }) =>
    navOpen &&
    css`
      position: fixed;
      width: 100%;
      height: 100vh;
    `}
`;

const BgTriangle = styled.div`
  width: 100%;
  height: 150px;
  background-image: linear-gradient(
    90deg,
    rgba(234, 40, 69, 1) 0%,
    rgba(243, 71, 78, 1) 100%
  );
  position: absolute;
  left: 0;
  top: 0;

  &:before {
    content: "";
    border-top: 30px solid transparent;
    border-right: 100vw solid #202020;
    position: absolute;
    left: 0;
    top: 120px;
  }
`;

const Container = styled.div`
  position: relative;
  width: 100%;
  max-width: 799px;
  margin: 0 auto;
  padding-left: 16px;
  padding-right: 16px;
`;
const Hr = styled.div`
  width: 100%;
  height: 1px;
  background-color: #333;
`;

const SectionTitle = styled.div`
  padding-bottom: 24px;
`;
const PreTitle = styled.div`
  font-size: 12px;
  margin-bottom: 4px;
`;
const Title = styled.h2`
  font-size: 32px;
  font-weight: normal;
  line-height: 1;
  margin-top: 0;
  margin-bottom: 0;
`;

const SectionRider = styled.div`
  padding-top: 20px;
`;
const RiderDesc = styled.div`
  margin-bottom: 24px;
`;
const RiderInfo = styled.div`
  display: flex;
  padding-bottom: 24px;
`;
const RiderInfoText = styled.div`
  flex: 1;
  word-break: break-all;
`;
const RiderInfoTitle = styled.span`
  color: #999;
`;
const RiderInfoBr = styled.span`
  display: block;
`;

const LinkButton = styled.button`
  width: 100%;
  height: 50px;
  background-color: #75ff6f;
  border: none;
  font-size: 16px;
  font-weight: bold;
  color: #202020;
  margin-bottom: 10px;
`;
const LinkText = styled.div`
  margin-bottom: 24px;
  color: #fff;
`;

const SectionDelivery = styled.div`
  color: #999;
  padding-top: 24px;
`;
const DeliveryGreeting = styled.div`
  color: #fff;
`;
const DeliveryHighlight = styled.div`
  color: #75ff6f;
`;
const DeliveryImageWrap = styled.div`
  margin-left: -16px;
  margin-right: -16px;
  padding-top: 24px;
  padding-bottom: 24px;
  font-size: 0;
`;
const DeliveryImage = styled.img`
  width: 100%;
`;

const SectionBanner = styled.div`
  padding-top: 24px;
`;

const SectionError = styled.div`
  padding-top: 110px;
`;
const SectionErrorImage = styled.img`
  height: 58px;
  display: block;
  margin: 0 auto 16px;
`;
const SectionErrorText = styled.div`
  text-align: center;
  margin-bottom: 156px;
`;

function Completed(props) {
  const { item } = useDeliveryStore().state;
  const { ...actions } = useDeliveryStore();

  const { navActive } = useNavStore().state;

  const [loaded, setLoaded] = useState(false);

  const fetchById = useCallback(
    async ({ token }) => {
      try {
        await actions.getCompleted({ token });
        setLoaded(true);
      } catch (err) {
        console.log(err);
        setLoaded(true);
      }
    },
    [actions],
  );

  useEffect(() => {
    const query = queryString.parse(
      props.location.search.replaceAll("+", "%2B"),
    );

    if (!loaded && (query.token || props.match.params.token)) {
      fetchById({ token: query.token || props.match.params.token });
    }
  }, [fetchById, props.location.search, props.match.params.token, loaded]);

  // 라이더 정보
  const riderMobile =
    item && item.deliveryRider && item.deliveryRider.mobile1
      ? item.deliveryRider.mobile1
      : null;

  // 배차시간
  const releasedAt = item && item.releasedAt ? new Date(item.releasedAt) : null;

  // 신청시간
  const deliveryCompletedDate =
    item && item.deliveryCompletedDate
      ? new Date(item.deliveryCompletedDate)
      : null;

  // 소요 시간
  let leftHours = 0;
  let leftMinutes = 0;

  let time = 0;

  if (releasedAt && deliveryCompletedDate) {
    time = differenceInMinutes(deliveryCompletedDate, releasedAt);
  }

  leftHours = parseInt(time / 60);
  leftMinutes = time % 60;

  return (
    <BodyContent navOpen={navActive}>
      <BgTriangle />
      <Nav />
      <SectionTitle>
        <Container>
          <PreTitle>4시간 오늘도착 배송혁명</PreTitle>
          <Title>배송완료안내</Title>
        </Container>
      </SectionTitle>
      {!loaded && (
        <SectionError>
          <Container>
            <SectionErrorImage
              src="/assets/images/warning.png"
              alt="잘못된 접근 이미지"
            />
            <SectionErrorText>
              배송정보를 가져오고 있습니다.
              <br />
              잠시만 기다려주세요.
            </SectionErrorText>
            <Hr />
          </Container>
        </SectionError>
      )}
      {loaded && item && (
        <>
          <SectionRider>
            <Container>
              <RiderDesc>
                두발히어로에서 시간 배송을 완료했습니다.
                <br />
                물품을 확인해주세요.
              </RiderDesc>
              <RiderInfo>
                <RiderInfoText>
                  <RiderInfoTitle>배송기사성명(연락처):</RiderInfoTitle>{" "}
                  <RiderInfoBr />
                  {item.deliveryRider ? item.deliveryRider.name : "두발히어로"}(
                  {riderMobile
                    ? `${riderMobile.substring(0, 3)}-${riderMobile.substring(
                        3,
                        7,
                      )}-${riderMobile.substring(7, 11)}`
                    : "-"}
                  )
                  <br />
                  <br />
                  <RiderInfoTitle>배송문의:</RiderInfoTitle> <RiderInfoBr />
                  <a href="mailto:check@chainlogis.com">
                    이메일 check@chainlogis.com
                  </a>
                  <br />
                  <a
                    href="http://pf.kakao.com/_GxeFxnxb/chat"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    카카오톡 1:1상담(터치하세요)
                  </a>
                </RiderInfoText>
              </RiderInfo>
              {item.tokenForPublicAPI && (
                <>
                  <a
                    href={`https://review.doobalhero.kr/?token=${item.tokenForPublicAPI}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <LinkButton type="button">배송기사 칭찬하기 </LinkButton>
                  </a>
                  <LinkText>
                    칭찬&#38;응원 등록 시 매월 진행되는 두발히어로 고객사 경품
                    이벤트에 자동 응모됩니다. 꼭 평가 남겨주세요.
                  </LinkText>
                </>
              )}

              <Hr />
            </Container>
          </SectionRider>
          <SectionDelivery>
            <Container>
              <DeliveryGreeting>
                {item.receiverName} 님, 두발히어로입니다.
              </DeliveryGreeting>
              {item.deliveryRiderName} 기사가 {item.senderName}에서 발송한
              접수번호 ({item.bookId}) 물품을 배송완료했습니다.
              <DeliveryHighlight>
                - 배송완료일시:{" "}
                {deliveryCompletedDate &&
                  format(deliveryCompletedDate, "M월 d일 H시 m분")}
                <br />- 출차 후 걸린 시간:{" "}
                {leftHours > 0 ? `${leftHours}시간` : ""} {leftMinutes}분
                <br />- 배송위치안내: {item.completedLocation}
              </DeliveryHighlight>
              배송메모(서명)/보관장소 사진을 확인해서 내 물품이 어디에 있는지
              확인해주세요.
              <DeliveryImageWrap>
                <DeliveryImage
                  src={item.completedImage}
                  alt="배송 완료 이미지"
                />
              </DeliveryImageWrap>
              {item.tokenForPublicAPI && (
                <>
                  <a
                    href={`https://problem.doobalhero.kr/?token=${item.tokenForPublicAPI}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <LinkButton type="button">
                      배송CS등록(오배송 등) 
                    </LinkButton>
                  </a>
                  <LinkText>
                    물품 보관 장소를 찾으실 수 없거나, 물품이 잘못 배송된 경우
                    배송CS를 등록해주십시오.
                    <br />
                    배송기사님이 최대한 빠르게 등록해주신 배송CS 내용을 확인하고
                    조치하겠습니다.
                  </LinkText>
                </>
              )}
              <Hr />
            </Container>
          </SectionDelivery>
        </>
      )}
      {loaded && !item && (
        <SectionError>
          <Container>
            <SectionErrorImage
              src="/assets/images/warning.png"
              alt="잘못된 접근 이미지"
            />
            <SectionErrorText>
              잘못된 접근입니다.
              <br />
              알림톡으로 받은 링크로 접속해주세요.
            </SectionErrorText>
            <Hr />
          </Container>
        </SectionError>
      )}

      <SectionBanner>
        <Container>
          <Banner />
        </Container>
      </SectionBanner>
      <Footer />
    </BodyContent>
  );
}

export default withQueryParams({ token: StringParam }, Completed);
