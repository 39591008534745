import React, { createContext, useCallback, useContext, useReducer } from "react";

export const CONSTANTS = {
  SET_NAV_ACTIVE: "SET_NAV_ACTIVE",
};

const INITIAL_STATE = {
  navActive: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case CONSTANTS.SET_NAV_ACTIVE:      
      return {
        ...state,
        navActive: !state.navActive
      };
    default:
      return INITIAL_STATE;
  }
};

export const Context = createContext(INITIAL_STATE);

export const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  return (
    <Context.Provider value={{ dispatch, state }}>{children}</Context.Provider>
  );
};

export const useNavStore = () => {
  const { dispatch, state } = useContext(Context);

  const toggleNav = useCallback(() => {
    dispatch({ type: CONSTANTS.SET_NAV_ACTIVE });
  }, [dispatch]);  

  return {
    state,
    toggleNav,
  };
};
