import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { createGlobalStyle } from "styled-components";
import { QueryParamProvider } from 'use-query-params';

import { DeliveryStore, NavStore } from './stores';

import Main from "./pages/index";
import Completed from "./pages/Completed";
import NotFound from "./pages/NotFound";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-size: 16px;
  }

  * {
    font-family: 'Spoqa Han Sans', 'Spoqa Han Sans JP', sans-serif;
    box-sizing: border-box;
  }

  h1, h2, h3, h4, h5 {
    font-family: 'Black Han Sans', 'Spoqa Han Sans', 'Spoqa Han Sans JP', sans-serif;
  }

  a {
    color: inherit;
    text-decoration: none;

    &:hover {
      color: inherit;
      text-decoration: none;
    }
  }
`;

const AppProvider = ({ contexts, children }) =>
  contexts.reduce(
    (prev, context) =>
      React.createElement(context, {
        children: prev,
      }),
    children
  );

const Root = () => (
  <AppProvider contexts={[
    QueryParamProvider,
    DeliveryStore.Provider,
    NavStore.Provider,
  ]}>
    <BrowserRouter>
      <GlobalStyle />
      <div>
        <Switch>
          <Route exact path="/" component={Main} />
          <Route exact path="/completed" component={Completed} />
          <Route exact path="/images/:token" component={Completed} />
          <Route component={NotFound} />
        </Switch>
      </div>
    </BrowserRouter>
  </AppProvider>
);

export default Root;
