export const DELIVERY_STATUS_0 = "DELIVERY_STATUS_RECEIPT";
export const DELIVERY_STATUS_1 = "DELIVERY_STATUS_PICKUP_SCHEDULED";
export const DELIVERY_STATUS_2 = "DELIVERY_STATUS_PICKUP_COMPLETED";
export const DELIVERY_STATUS_3 = "DELIVERY_STATUS_WAREHOUSED";
export const DELIVERY_STATUS_4 = "DELIVERY_STATUS_DELIVERY_STARTED";
export const DELIVERY_STATUS_5 = "DELIVERY_STATUS_DELIVERED";
export const DELIVERY_STATUS_6 = "DELIVERY_STATUS_DELIVERY_SENT_BACK";
export const DELIVERY_STATUS_7 = "DELIVERY_STATUS_DELIVERY_LOST";
export const DELIVERY_STATUS_8 = "DELIVERY_RIDER_ALLOCATED";

export class Delivery {
  accidentAt = null;
  bookId = "";
  canceledAt = null;
  canceledReason = null;
  delayedDeliveries = [];
  deliveriesCountLeftYet = null;
  deliveryCompletedDate = null;
  deliveryRider = {};
  estimatedDeliveredAt = null;
  etc4 = "{}";
  isAccident = false;
  isCanceled = false;
  notReceivedImageLocation = null;
  order = null;
  pickupDateCompleted = null;
  pickupDateScheduled = null;
  pickupRider = {};
  receiptDate = null;
  receiverAddress = null;
  receiverAddressRoad = null;
  receiverMobile = "";
  receiverName = null;
  releasedAt = null;
  senderName = null;
  sentBackDate = null;
  sentBackLogs = [];
  signImageLocation = null;
  status = null;
  time = null;
  tokenForPublicAPI = null;
  warehousedAt = null;

  constructor(delivery) {
    this.accidentAt = delivery.accidentAt;
    this.bookId = delivery.bookId;
    this.canceledAt = delivery.canceledAt;
    this.canceledReason = delivery.canceledReason;
    this.delayedDeliveries = delivery.delayedDeliveries;
    this.deliveriesCountLeftYet = delivery.deliveriesCountLeftYet;
    this.deliveryCompletedDate = delivery.deliveryCompletedDate;
    this.deliveryRider = delivery.deliveryRider;
    this.estimatedDeliveredAt = delivery.estimatedDeliveredAt;
    this.etc4 = delivery.etc4;
    this.isAccident = delivery.isAccident;
    this.isCanceled = delivery.isCanceled;
    this.notReceivedImageLocation = delivery.notReceivedImageLocation;
    this.order = delivery.order;
    this.pickupDateCompleted = delivery.pickupDateCompleted;
    this.pickupDateScheduled = delivery.pickupDateScheduled;
    this.pickupRider = delivery.pickupRider;
    this.receiptDate = delivery.receiptDate;
    this.receiverAddress = delivery.receiverAddress;
    this.receiverAddressRoad = delivery.receiverAddressRoad;
    this.receiverMobile = delivery.receiverMobile;
    this.receiverName = delivery.receiverName;
    this.releasedAt = delivery.releasedAt;
    this.senderName = delivery.senderName;
    this.sentBackDate = delivery.sentBackDate;
    this.sentBackLogs = delivery.sentBackLogs;
    this.signImageLocation = delivery.signImageLocation;
    this.status = delivery.status;
    this.time = delivery.time;
    this.tokenForPublicAPI = delivery.tokenForPublicAPI;
    this.warehousedAt = delivery.warehousedAt;
  }

  get completedLocation() {
    return this.deliveryEtc4Obj ? this.deliveryEtc4Obj.completedLocation : "";
  }

  get deliveryRiderName() {
    return this.deliveryRider ? this.deliveryRider.name : "두발히어로";
  }

  get deliveryEtc4Obj() {
    try {
      return this.etc4 && JSON.parse(this.etc4);
    } catch (e) {
      return {};
    }
  }

  get completedImage() {
    if (this.deliveryEtc4Obj.signImageLocation) {
      return this.deliveryEtc4Obj.signImageLocation;
    } else if (this.deliveryEtc4Obj.notReceivedImageLocation) {
      return this.deliveryEtc4Obj.notReceivedImageLocation;
    } else {
      return this.signImageLocation || this.notReceivedImageLocation;
    }
  }
}
